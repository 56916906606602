<template>
  <div class="mt-4">
    <LoginDlg />
  </div>
</template>

<script>
import LoginDlg from '@/pages/login/LoginDlg'

export default {
  name: 'LoginPage',
  components: { LoginDlg },
}
</script>
