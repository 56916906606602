<template>
  <v-container>
    <v-row>
      <v-col
        md="8"
        offset-md="2"
        sm="8"
        offset-sm="2"
        lg="6"
        offset-lg="3"
        xl="4"
        offset-xl="4"
      >
        <v-card>
          <v-form ref="formreg" v-model="valid">
            <v-toolbar dark color="primary">
              <v-toolbar-title> Восстановление пароля </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <div class="mb-4">
                <div class="mb-2">
                  Придумайте новый пароль для
                  <span class="font-weight-bold">{{ maskedEmail }}</span>
                </div>
                <div>Внимание! Пароль должен быть не короче 8 знаков</div>
              </div>

              <v-text-field
                label="Пароль"
                :counter="DEFAULT_PASSWORD_MIN_LENGTH"
                v-model="password1"
                :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword1 ? 'text' : 'password'"
                @click:append="showPassword1 = !showPassword1"
                @input="onInput"
                maxlength="DEFAULT_PASSWORD_MAX_LENGTH"
                :rules="passwordRules"
              ></v-text-field>
              <v-text-field
                label="Повторите пароль"
                :counter="DEFAULT_PASSWORD_MIN_LENGTH"
                v-model="password2"
                :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword2 ? 'text' : 'password'"
                @click:append="showPassword2 = !showPassword2"
                @input="onInput"
                maxlength="DEFAULT_PASSWORD_MAX_LENGTH"
                :rules="confirmPasswordRules"
              ></v-text-field>

              <v-alert v-if="showAlert" outlined type="error" class="my-3">
                {{ alertMessage }}
              </v-alert>

              <v-btn
                @click="onLogin"
                :disabled="!valid"
                block
                depressed
                color="primary"
                class="mt-3"
                >Установить пароль
              </v-btn>

              <div v-if="showSuccess">
                Успешно! Сейчас перенапрявлю вас на старницу входа
              </div>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import {
  DEFAULT_PASSWORD_MIN_LENGTH,
  DEFAULT_PASSWORD_MAX_LENGTH,
} from '@/config/paramsDefault'
export default {
  name: 'ResetPasswordPage',
  data() {
    return {
      token: '',
      maskedEmail: '',
      password1: '',
      password2: '',
      showPassword1: false,
      showPassword2: false,
      showAlert: false,
      alertMessage: '',
      showSuccess: false,
      valid: false,
      DEFAULT_PASSWORD_MIN_LENGTH,
      DEFAULT_PASSWORD_MAX_LENGTH,
      passwordRules: [
        v => !!v || 'Введите пароль',
        v =>
          (v && v.length >= DEFAULT_PASSWORD_MIN_LENGTH) ||
          `Пароль должен содержать не менее ${DEFAULT_PASSWORD_MIN_LENGTH} символов`,
        v =>
          (v && v.length <= DEFAULT_PASSWORD_MAX_LENGTH) ||
          `Пароль должен содержать не более ${DEFAULT_PASSWORD_MAX_LENGTH} символов`,
      ],
      confirmPasswordRules: [
        v => !!v || 'ПВведите пароль повторно',
        v => v === this.password1 || 'Введённые пароли не совпадают.',
      ],
    }
  },
  async created() {
    await this.logout(false)
    this.token = this.$route.params.token
    try {
      this.maskedEmail = await this.getUserInfo(this.token)
    } catch {
      await this.$router.push('/login')
    }
  },
  methods: {
    ...mapActions(['getUserInfo', 'resetPassword', 'logout']),

    onInput() {
      this.showAlert = false
    },
    validateForm() {
      if (this.password1.length < 8) {
        this.alertMessage = 'Пароль не меньше 8 знаков'
        this.showAlert = true
        return false
      }
      if (this.password1 !== this.password2) {
        this.alertMessage = 'Пароли не совпадают'
        this.showAlert = true
        return false
      }
      return true
    },
    handleLoginError() {
      this.alertMessage =
        'Произошла ошибка, повторите операцию или попробуйте позже'
      this.showAlert = true
      this.valid = false
    },
    handleLoginSuccess() {
      this.showSuccess = true
      setTimeout(() => this.$router.push(`/login`), 3000)
    },

    async onLogin() {
      if (!this.validateForm()) return

      this.valid = false
      try {
        await this.resetPassword({
          token: this.token,
          newPassword: this.password1,
        })
        this.handleLoginSuccess()
      } catch {
        this.handleLoginError()
      }
    },
  },
}
</script>
